







import { Component, Vue } from 'vue-property-decorator';
import CarRateAlertConfigsPopup from '@/modules/cars/modules/settings/components/cars-rate-alert-configs-popup.vue';

@Component({
    components: { CarRateAlertConfigsPopup },
})
export default class CarRateAlertConfigsPopupPage extends Vue {
}
